import React, { useState, useEffect, useRef, forwardRef } from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Hero from '../components/Hero'
import { strengthList, NAV_HEIGHT } from '../assets/lib/constants'
import { graphql } from 'gatsby'
import { animateScroll as scroll } from 'react-scroll'

const scrollToInitialPosition = () => {
  scroll.scrollTo(400, { duration: 500 })
}

const StrengthsTab = forwardRef((props, ref) => {
  const { tabIndex, setTabIndex } = props
  return (
  <section className="strengths__tab" ref={ref}>
    <div className="strengths__tab-inner">
      <ul className="strengths__list">
        {strengthList.map((strength, index) => {
          return (
            <li
              className={`strengths__list-item strengths__list-item--${strength.key} ${index === tabIndex ? 'strengths__list-item--active' : 'strengths__list-item--inactive'}`}
              key={strength.key}
              onClick={() => {
                setTabIndex(index)
                scrollToInitialPosition()
              }}
            >
              <div className="strengths__icon">
                <img src={`../image/top/ico_${strength.key}.svg`} alt="" />
              </div>
                <p className={`strengths__title ${index === tabIndex && 'strengths__title--active'}`}>{strength.title}</p>
            </li>
          )
        })}
      </ul>
    </div>
  </section>  
  )
})

const StrengthsTabSmall = forwardRef((props, ref) => {
  const { tabIndex, setTabIndex, scrollStyle } = props
  return (
  <section className="strengths__tab" style={scrollStyle} ref={ref}>
    <div className="strengths__tab-inner">
      <ul className="strengths__list">
        {strengthList.map((strength, index) => {
          return (
            <li
              className={`strengths__list-item strengths__list-item--small strengths__list-item--${strength.key} ${index === tabIndex ? 'strengths__list-item--active' : 'strengths__list-item--inactive'}`}
              key={strength.key}
              onClick={() => {
                setTabIndex(index)
                scrollToInitialPosition()
              }}
            >
              <p className={`strengths__title strengths__title--small ${index === tabIndex && 'strengths__title--active'}`}>{strength.title}</p>
            </li>
          )
        })}
      </ul>
    </div>
  </section>  
)})

const StrengthsPageContent = ({ contents }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [offsetTop, setOffsetTop] = useState(0)
  const [tabSmallMarginTop, setTabSmallMarginTop] = useState(0)

  const tabRef = useRef()
  const tabSmallRef = useRef()

  const scrollTop = () => {
    if (typeof window === 'undefined') return 0
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  }

  useEffect(() => {
    const tabHeight = tabRef.current.clientHeight
    const tabSmallHeight = tabSmallRef.current.clientHeight

    const onScroll = () => {
      const position = scrollTop()

      if (position > offsetTop - NAV_HEIGHT + tabHeight) {
        const underY = position - offsetTop + NAV_HEIGHT - tabHeight
        const underYEnd = 200
        // calcedTabSmallMarginTop: 
        // when underY is 0, it should be NAV_HEIGHT - tabSmallHeight
        // when underY is underYEnd, it should be NAV_HEIGHT
        const calcedTabSmallMarginTop = underY * tabSmallHeight / underYEnd + NAV_HEIGHT - tabSmallHeight
        if (underY > underYEnd) {
          setTabSmallMarginTop(NAV_HEIGHT)
        } else {
          setTabSmallMarginTop(calcedTabSmallMarginTop)
        }
      } else {
        if (tabSmallMarginTop !== 0) setTabSmallMarginTop(0)
      }
    }

    setOffsetTop(tabRef.current.offsetTop)

    document.addEventListener("scroll", onScroll)
    return () => {
      document.removeEventListener("scroll", onScroll)
    }
  }, [offsetTop, tabSmallMarginTop])

  useEffect(() => {
    // for internal link (from content 'price')
    if (window.location.hash) {
      let selectedStrengthIndex = null
      strengthList.forEach((strength, index) => {
        if (window.location.hash.replace('#', '') === strength.key) {
          selectedStrengthIndex = index
        }
      })
      if (selectedStrengthIndex !== null ) {
        window.scrollTo({top: 0, behavior: 'auto' })
        setTabIndex(selectedStrengthIndex)
        window.history.replaceState(null, null, window.location.href.split('?link=to#')[0])
      }
    }
  }, [])

  const scrollStyle = tabSmallMarginTop === 0
    ? { opacity: 0, position: 'fixed', zIndex: -1} : { marginTop: `${tabSmallMarginTop}px`, position: 'fixed', top: 0 }

  return (
    <div className="main">
      <StrengthsTab tabIndex={tabIndex} setTabIndex={setTabIndex} ref={tabRef} />
      <StrengthsTabSmall tabIndex={tabIndex} setTabIndex={setTabIndex} scrollStyle={scrollStyle} ref={tabSmallRef}/> 
      {strengthList.map((strength) => {
        const filteredContent = contents.filter((content) => content.fileAbsolutePath.includes(`${strength.key}.md`))
        if (strengthList[tabIndex].key === strength.key) {
          return (
            <section className="section" key={strength.key}>
              <div className="section__inner section__inner--recruit">
                <div
                  className="strengths__content"
                  dangerouslySetInnerHTML={{ __html: filteredContent[0].html }}
                />
              </div>
            </section>
          )
        } else {
          return null
        }
      })}
    </div>
  )
}

const StrengthsPage = (props) => (
  <Layout
    title="３つの強み | 株式会社もばらぶ"
    description="株式会社もばらぶの３つの強みの紹介ページです。弊社のソフトウェア開発では、「高品質な開発」「適正価格なシステム」「柔軟な対応」を掲げています。"
    pathName={props.location.pathname}
  >
    <Helmet />
    <Header />
    <Hero
      title="THREE STRENGTHS"
      titleJa="三つの強み"
      body="弊社のソフトウェア開発では、「高品質な開発」「適正価格なシステム」「柔軟な対応」を掲げています。"
    />
    <StrengthsPageContent contents={props.data.allMarkdownRemark.nodes} />
  </Layout>
)

export default StrengthsPage

export const pageQuery = graphql`
  query StrengthsPage {
    allMarkdownRemark {
      nodes {
        html
        frontmatter {
          title
        }
        fileAbsolutePath
      }
    }
  }
`